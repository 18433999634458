import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import Hosting from '../../lib/Hosting/Hosting';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import Spacer from '../../components/Spacer/Spacer';
import { Card, CardHeader } from '@fluentui/react-card';
import { Badge, Button, Caption1, Dialog, DialogSurface, DialogTitle, Link, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Text } from '@fluentui/react-components';
import CPanelHostingCard from '../../components/Hosting/CPanelHostingCard/CPanelHostingCard';
import DeployHostingServiceDrawer from '../../components/Hosting/DeployHostingServiceDrawer/DeployHostingServiceDrawer';
import { ArrowEnter20Regular, CheckmarkCircle12Regular, CheckmarkCircle16Filled, CheckmarkCircle16Regular, DismissCircle12Regular, Globe16Regular } from "@fluentui/react-icons";

import cpanel_logo from './../../assets/cpanel_logo.png';  
import Global_Error from '../../lib/GlobalError/GlobalError';

export default class HostingView extends React.Component {

  private styles: any = {};
  private breadcrumbItems: IBreadcrumbItem[];

  public constructor(props: any) {
    super(props);

    // define breadcrumb items...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/dashboard") },
      { text: 'Your Account', key: 'manage_account', isCurrentItem: true },
    ];

    // define initial state...
    this.state = {
      data_loading: true,
      hosting_services: [],

      deploy_hosting_drawer_open: false,
      is_redirecting: false
    };
    this.load_data();

    // define the view styles..
    this.styles = {
      breadcrumb_wrapper: {
        marginLeft: -7
      },

      services_wrapper: {
        display: "flex",
        flexDirection: "row",
        gap: 15,
        marginLeft: 1
      },

      service_card: {
        width: 400
      }
    };
  }

  public load_data = async () => {
    try {
      const hostingServices = await Hosting.get_hosting_services();
      this.setState({
        data_loading: false,
        hosting_services: hostingServices
      });

    }
    catch(error) {
      throw error;
    }
  }

  public cpanel_auth_redirect = async (service_id: number) => {
    this.setState({
      is_redirecting: true
    });

    const cpanelHostingRedirect = await Hosting.get_cpanel_session_redirect_url(service_id);
    console.log(cpanelHostingRedirect);
    if(cpanelHostingRedirect.can_redirect) {
      window.open(cpanelHostingRedirect.redirect_url);

    }
    else {
      const globalError = new Global_Error()
        globalError.display_user_visible_error({
          error_title: "Could not redirect you to cPanel",
          error_content: cpanelHostingRedirect.reason
        });
  
        globalError.dialog_events.on("dialog_close", () => {
          globalError.dialog_events.removeAllListeners();
          this.setState({
            is_submitting: false
          });
        });
    }

    this.setState({
      is_redirecting: false
    });
  }

  private get_remote_status_badge(remote_status: string): any {
    if(remote_status == "terminated") {
      return (
        <Badge color='danger'>
          <DismissCircle12Regular />
          <span style={{marginLeft: 5}}>Terminated</span>
        </Badge>
      );
    }
    else if(remote_status == "active") {
      return (
        <Badge color='success'>
          <CheckmarkCircle12Regular /> 
          <span style={{marginLeft: 5}}>Service Active</span>
        </Badge>
      );
    }
    else {
      // unknown status....
      <Badge>{remote_status}</Badge>
    }
  }

  private get_hosting_control_panel_logo(control_panel_type: string) {
    if(control_panel_type == "cPanel") {
      return (
        <img height='18px' src={cpanel_logo} />
      );
    }
    else {
      return (
        <span>{control_panel_type}</span>
      );
    }
  }

  public render() {
    const state = this.state as any;
    return (
      <SafeArea>
        <DeployHostingServiceDrawer 
          display={state.deploy_hosting_drawer_open}
          onCancel={() => {
            this.setState({
              deploy_hosting_drawer_open: false
            });
          }} 
          onComplete={() => {}}
        />

        {state.data_loading? (
          <ViewLoader message='Loading your hosting services' />

        ): (
          <div>
            <div style={this.styles.breadcrumb_wrapper}>
              <Breadcrumb
                items={this.breadcrumbItems}
                maxDisplayedItems={5}
                ariaLabel="Breadcrumb with static width"
                overflowAriaLabel="More links"
              />
            </div>
            <h1>Website Hosting Services</h1>

            <Spacer size="25px" />

            <div style={{maxWidth: 1200, marginLeft: 1}}> 
              <Card>
                <CardHeader
                  header={<Text weight="semibold">Your Website Hosting Services</Text>}
                  description={
                    <></>
                  }
                  action={
                    <></>
                  }
                />

                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell style={{paddingLeft: 0}}><b>Primary Domain</b></TableHeaderCell>
                      <TableHeaderCell><b>Availability Zone</b></TableHeaderCell>
                      <TableHeaderCell><b>Date Created</b></TableHeaderCell>
                      <TableHeaderCell><b>Account Status</b></TableHeaderCell>
                      <TableHeaderCell><b>Control Panel Type</b></TableHeaderCell>
                      <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                  </TableHeader>

                  {state.hosting_services.map((hostingService: any, index: number) => (
                    // <div style={this.styles.service_card}>
                    //   <CPanelHostingCard
                    //     primary_domain={hostingService.primary_domain}
                    //     service_id={hostingService.order_id}
                    //     hosting_zone='Northern Europe ( Dublin, Ireland 🇮🇪 )'
                    //   />
                    // </div>
                    <TableRow>
                      <TableCell>
                        <Link as='a' target="_blank" rel="noopener noreferrer" href={"https://" + hostingService.primary_domain}>
                          {hostingService.primary_domain}
                        </Link>
                        </TableCell>
                      <TableCell>{hostingService.availability_zone}</TableCell>
                      <TableCell>{hostingService.date_created}</TableCell>
                      <TableCell>{this.get_remote_status_badge(hostingService.remote_status)}</TableCell>
                      <TableCell>{this.get_hosting_control_panel_logo(hostingService.control_panel_type)}</TableCell>
                      <TableCell>
                        {hostingService.remote_status == "active"? (
                          <Button onClick={() => {
                            this.cpanel_auth_redirect(hostingService.order_id)
                          }} appearance='primary'>
                            Access cPanel
                          </Button>
                        ): (
                          <Button disabled appearance='primary'>Access cPanel</Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                </Table>
              </Card>
            </div>

            {/* <div style={this.styles.services_wrapper}>
              {state.hosting_services.map((hostingService: any, index: number) => (
                <div style={this.styles.service_card}>
                  <CPanelHostingCard
                    primary_domain={hostingService.primary_domain}
                    service_id={hostingService.order_id}
                    hosting_zone='Northern Europe ( Dublin, Ireland 🇮🇪 )'
                  />
                </div>
              ))}
            </div> */}
          </div>
        )}

        <Dialog open={state.is_redirecting}>
          <DialogSurface>
            <ViewLoader height='250px' message='Accessing cPanel. Please Wait...' />
          </DialogSurface>
        </Dialog>

      </SafeArea>
    );
  }

}