import { DefaultButton, Icon, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, TextField, initializeIcons } from '@fluentui/react';
import React from 'react';
import Spacer from '../Spacer/Spacer';
import { Button } from '@fluentui/react-components';
import Domain_Name from '../../lib/DomainName/DomainName';

initializeIcons();

export interface DomainNameserverPanelProps {
  isOpen: boolean,
  nameservers: {
    nameserverRecordName: string,
    value: string
  },

  domainName: string,

  onDismiss: any
};

export class DomainNameserverEditPanel extends React.Component <DomainNameserverPanelProps> {

  constructor(props: any) {
    super(props);

    if(this.props.nameservers) {
      const nameservers = this.props.nameservers as any;

      this.state = {
        nameserver_1: nameservers[0],
        nameserver_2: nameservers[1],
        nameserver_3: nameservers[2],
        nameserver_4: nameservers[3],

        nameservers_updating: false
      };
    }
  }

  private onPanelDismiss = (nameservers_have_updated: boolean) => {
    if(typeof this.props.onDismiss !== 'undefined') {
      this.props.onDismiss(nameservers_have_updated);
    }
  }

  private update_nameservers = async () => {
    try {
      this.setState({
        nameservers_updating: true
      });

      // perform the nameserver update...
      const state = this.state as any;
      let updatedDomainNameservers: Array<string> = [];
      updatedDomainNameservers.push(state.nameserver_1['value']);
      updatedDomainNameservers.push(state.nameserver_2['value']);
      updatedDomainNameservers.push(state.nameserver_3['value']);
      updatedDomainNameservers.push(state.nameserver_4['value']);

      await Domain_Name.update_nameservers(this.props.domainName, updatedDomainNameservers);
      this.setState({
        nameservers_updaging: false
      });

      // pass the nameserver change status to the parental component...
      this.onPanelDismiss(true);
    }
    catch(error) {
      throw error;
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <Panel
        isOpen={this.props.isOpen}
        onDismiss={() => this.onPanelDismiss(false)}
        closeButtonAriaLabel="Close"
        headerText="Update Nameservers"
      >
        <Spacer size='15px' />
        
        <TextField 
          disabled={state.nameservers_updating} 
          label='Nameserver 1' 
          value={state.nameserver_1.value}
          onChange={(event) => {
            this.setState({
              nameserver_1: {
                value: (event.target as any).value
              }
            });
          }}
        />
        <TextField 
          disabled={state.nameservers_updating} 
          label='Nameserver 2' 
          value={state.nameserver_2.value} 
          onChange={(event) => {
            this.setState({
              nameserver_2: {
                value: (event.target as any).value
              }
            });
          }}
        />
        <TextField 
          disabled={state.nameservers_updating}
          label='Nameserver 3' 
          value={state.nameserver_3.value}
          onChange={(event) => {
            this.setState({
              nameserver_3: {
                value: (event.target as any).value
              }
            });
          }}
        />
        <TextField 
          disabled={state.nameservers_updating} 
          label='Nameserver 4' 
          value={state.nameserver_4.value}
          onChange={(event) => {
            this.setState({
              nameserver_4: {
                value: (event.target as any).value
              }
            });
          }}
        />

        <Spacer size='15px' />
        <p>
          Caution, this setting may
          interupt services that your domain name is connected to.
        </p>

        {/* <Button>
          <Icon iconName="Undo" styles={{ root: { paddingRight: 5, color: '#0078d4' } }} />
          Reset to default nameservers
        </Button> */}

        <Spacer size='15px' />
        <PrimaryButton disabled={state.nameservers_updating} onClick={this.update_nameservers}>
          {state.nameservers_updating? (
            <span>
              <Spinner size={SpinnerSize.small} />
            </span>
          ) : (
            "Save Changes"
          )}
        </PrimaryButton>
        <DefaultButton disabled={state.nameservers_updating} onClick={() => this.onPanelDismiss(false)} style={{marginLeft: 5}}>Cancel</DefaultButton>
      </Panel>
    );
  }

}