/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

import Backend_Request from "../Request/BackendRequest";

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
 */

/**
 * Represents a generic domain name with its associated properties.
 */
export interface Generic_Domain_Name {
  /** The name of the domain. */
  domain_name: string;
  
  /** The date the domain was ordered. */
  order_date: string;
  
  /** Indicates whether the domain has been cancelled. */
  is_cancelled: boolean;
  
  /** The date the domain is set to renew. */
  renew_date: string;
};

/**
 * Enum representing the nameserver settings for a domain name.
 */
export enum Domain_Name_Nameserver_Setting {
  /** Uses REG121 DNS settings. */
  REG121_DNS,
  
  /** Uses external nameserver settings. */
  EXTERNAL
};

/**
 * Represents a nameserver associated with a domain.
 */
export interface Domain_Nameserver {
  /** The priority of the nameserver. */
  priority: number;
  
  /** The display name of the nameserver. */
  display_name: string;
  
  /** The value of the nameserver (e.g., IP address). */
  value: string;
};

/**
 * Represents a detailed domain name with additional nameserver settings.
 */
export interface Detailed_Domain_Name {
  /** The name of the domain. */
  domain_name: string;
  
  /** The date the domain was ordered. */
  order_date: string;
  
  /** Indicates whether the domain has been cancelled. */
  is_cancelled: boolean;
  
  /** The date the domain is set to renew. */
  renew_date: string;

  /** The nameservers associated with the domain. */
  nameservers: object;

  /** The nameserver setting for the domain. */
  nameserver_setting: Domain_Name_Nameserver_Setting;
};


/**
 * Class representing domain name operations.
 * This class contains static methods to interact with domain names, 
 * including fetching a list of domain names and retrieving detailed information 
 * about a specific domain.
 */
export default class Domain_Name {

  /**
   * Retrieves a list of domain names from the backend.
   * This method sends a request to the backend to get domain orders 
   * and returns the response data.
   * 
   * @returns A promise that resolves to void if successful. 
   *          It will throw an error if the request fails.
   */
  public static async get_domain_names(): Promise<void> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/domains/get",
        requestBody: {},
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        return result.response_data.domain_orders;
      } else {
        throw "Cannot get domain names...";
      }

    } catch (error) {
      throw error;
    }
  }

  /**
   * Retrieves detailed information about a specific domain name.
   * This function sends a request to the backend to retrieve information
   * about the specified domain name.
   * 
   * @param domain_name - The name of the domain to retrieve information for.
   * @returns A promise that resolves to a Detailed_Domain_Name object containing
   *          details about the domain.
   * @throws Will throw an error if the request fails or if the domain information
   *         cannot be retrieved.
   */
  public static async get_domain_name(domain_name: string): Promise<Detailed_Domain_Name> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/domain/get",
        requestBody: { domain_name },
        verbose: false
      });

      const result = await request.perform();

      if (result.is_success) {
        return {
          domain_name: result.response_data.domain.domain_name,
          order_date: result.response_data.domain_order.order_date,
          renew_date: result.response_data.domain_order.renew_date,
          is_cancelled: false,

          nameserver_setting: Domain_Name_Nameserver_Setting.REG121_DNS,
          nameservers: result.response_data.nameservers
        };
      } else {
        throw "Cannot get domain names...";
      }

    } catch (error) {
      throw error;
    }
  }

  /**
   * Updates the nameservers for a given domain.
   *
   * @param {string} domain_name - The domain name to update.
   * @param {Array<string>} nameservers - An array of nameservers (minimum of 2 required, maximum of 4).
   * @returns {Promise<any>} - Resolves to `true` if successful, otherwise throws an error.
   *
   * @throws {Error} If the number of nameservers is less than 2.
   * @throws {Error} If the update request fails.
   *
   * The function constructs a request body with the provided nameservers and
   * sends an update request to the backend API. If four nameservers are provided,
   * the request is sent in verbose mode.
  */
  public static async update_nameservers(domain_name: string, nameservers: Array<string>): Promise<any> {
    try {
      if (nameservers.length < 2) {
        throw new Error("Nameservers length must be at least 2.");
      }
  
      const requestBody: { [key: string]: string } = {
        domain_name: domain_name,
        nameserver_1: nameservers[0],
        nameserver_2: nameservers[1],
      };
  
      if (nameservers.length >= 3) {
        requestBody.nameserver_3 = nameservers[2];
      }
  
      if (nameservers.length === 4) {
        requestBody.nameserver_4 = nameservers[3];
      }
  
      const request = new Backend_Request({
        endpoint: "v2/domain/nameservers/update",
        requestBody: requestBody,
        verbose: nameservers.length === 4, // Verbose if 4 nameservers
      });
  
      const result = await request.perform();
  
      if (result.is_success) {
        console.log('Nameservers updated successfully.');
        return true; // Return true on success
      } else {
        throw new Error("Could not update domain nameservers.");
      }
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error for further handling if necessary
    }
  }
  
}
