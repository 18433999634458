import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';

export default class DomainRegisterView extends React.Component {

  private breadcrumbItems: IBreadcrumbItem[];

  public constructor(props:any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate("/") },
      { text: 'Domain Names', key: 'domain_names', onClick: () => {(this.props as any).navigate('/domains');} },
      { text: 'Register Domain Name', key: 'register_domain_name', isCurrentItem: true },
    ];
  }

  public render () {
    return (
      <SafeArea>
        <div style={{width: "100%"}}>
          <Breadcrumb
            style={{marginLeft: -6}}
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
          <h1>Register Domain Name</h1>
        </div>
      </SafeArea>
    );
  }

}