import React from 'react';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { getTheme, IconButton, Image, mergeStyles, Stack, Text, TextField } from '@fluentui/react';
import { ReactComponent as BrandLogoSvg } from './../../assets/branding/brand.svg';
import HeaderProfile from './HeaderProfile';
import Authentication_Service from '../../lib/Authentication/AuthenticationService';
import Backend_Request from '../../lib/Request/BackendRequest';
import store from '../../redux/ReduxStore';
import accountStore from '../../redux/Account/AccountRedux';

export default class Header extends React.Component {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    const authenticated = Authentication_Service.is_authenticated();

    const reduxState = accountStore.getState();
    this.state = {
      displayHeader: authenticated,
      first_name: reduxState.account.first_name,
      last_name: reduxState.account.last_name,
      searchQuery: '' // State for search query
    };

    this.styles = {
      nav: {
        backgroundColor: '#F4F4F4',
        padding: 0,
        width: '100vw',
        height: 55,
        paddingTop: 15,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 999
      },
      contentWrapper: {
        marginTop: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      searchContainer: {
        marginLeft: 15,
        flex: 1,
      },
      searchField: {
        width: 300,
      }
    };
  }

  public render() {
    const navLinks: INavLink[] = [
      { key: 'home', name: 'Home', url: '#' },
      { key: 'about', name: 'About', url: '#about' },
      { key: 'services', name: 'Services', url: '#services' },
      { key: 'contact', name: 'Contact', url: '#contact' }
    ];
    
    const navLinkGroups: INavLinkGroup[] = [
      {
        links: navLinks
      }
    ];

    if ((this.state as any).displayHeader) {
      const state = this.state as any;

      return (
        <div style={this.styles.nav}>
          <div style={this.styles.contentWrapper}>
            {/* Add your logo here if needed */}
            <div style={this.styles.searchContainer}>

            </div>
            <div style={{ paddingRight: 15 }}>
              <HeaderProfile
                first_name={state.first_name}
                last_name={state.last_name}
                onNavigateOut={(url: string) => {
                  window.location.assign(url);
                }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (<div />);
    }
  }
}
