/**
 * 121 Digital Console is a trading name of 121 Digital Services Limited
 *
 * @category    Web Application
 * @package     121 Digital Core
 * @subpackage  Backend Request
 * @author      James Gibbons <jgibbons@121digital.co.uk>
 * @license     https://www.121digital.co.uk/license 121 Digital Software License
 * @link        https://www.121digital.co.uk
 */

/**
 * Disclaimer: This source code and its comments are the intellectual property
 * of 121 Digital Console, a trading name of 121 Digital Services Limited. It may not be used, modified,
 * or distributed without explicit permission from 121 Digital Console, a trading name of 121 Digital Services Limited.
*/
import store from '../../redux/ReduxStore';
import Keycloak_Env from './KeycloakEnv';
import * as env from './../../env/env';
import Global_Error from '../GlobalError/GlobalError';

import axios from 'axios';
import globalSpinnerStore from '../../redux/GlobalSpinner/GlobalSpinnerRedux';
import globalErrorStore from '../../redux/GlobalError/GlobalErrorRedux';
import Log from '../Log/Log';
import Backend_Request from '../Request/BackendRequest';

/**
 * Interface representing the authentication request response.
 */
export default interface Authentication_Request_Response {
  auth_result: boolean;
  has_error: boolean;
}

/**
* Class representing the authentication service.
*/
export default class Authentication_Service {

  /**
   * This static method checks if the user is authenticated by inspecting the Redux store.
   * It retrieves the global authentication state from the Redux store and returns true if the user is authenticated.
   * 
   * @returns A boolean indicating whether the user is authenticated.
  */
  public static is_authenticated(): boolean {
    const reduxState = store.getState();
    // Log.dev_log(reduxState);

    if(reduxState.global_auth.is_authenticated) {
      // TODO: verify the token is still valid and possibly refresh it if needed?!!
      return true;

    }
    else {
      return false;
    }
  }

  /**
   * Function used to check if the requested URL is within the no-auth
   * guest URL range. The guest URL's can update a redux state to require
   * user auth though, so this will be checked in here if needed....
   * 
   * @return Boolean Is it a valid guest URL that can be accessed.
   * 
   */
  private static is_guest_url() {
    const pathname = window.location.pathname;
    if(pathname.split("/")[1] == "guest") {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Function used to redirect the user to to the auth screens, in the event
   * of the auth token or the refresh token not being valid. It will also check
   * to see if the user is trying to access a guest view, in which case they do
   * not need to have an auth token.
   * 
   * We will also verify that the user is not already on a guest screen or the
   * auth views, otherwise it will crete a redirection loop.
   * 
  */
  private static redirect_to_auth_loop_safe(): void {
    if (typeof window !== 'undefined' && 
        window.location.pathname !== "/auth/login" && 
        window.location.pathname !== "/auth/recovery" &&
        window.location.pathname !== "/auth/recovery/reset-password" &&
        !Authentication_Service.is_guest_url()) {
  
      // Encode the current URL to safely pass it as a query parameter.
      const returnLocationHref = encodeURIComponent(window.location.href);
      
      // Redirect the user to the login page with the return URL.
      window.location.assign(`/auth/login?return_url=${returnLocationHref}`);
    }
  }

  public static async refresh_authentication_token(): Promise<void> {
    try {
      Log.dev_log("--> Reloading session...");

      // block and que API requests while the API key is refreshing.
      store.dispatch({
        type: "REFRESH_UPDATE",
        payload: true
      });

      const reduxState = store.getState();
      const accessToken = reduxState.global_auth.auth_data.access_token;
      const refreshToken = reduxState.global_auth.auth_data.refresh_token;

      const apiServer = env.default.API_REMOTE_SERVER;
      const response = await axios.post(
        apiServer + "v2/token/refresh",
        {
          refresh_token: refreshToken,
          access_token: accessToken,
          client_id: env.default.KEYCLOAK_CLIENT_ID
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }
      );

      const responseData = response.data.response_data;
      if(responseData.token_refreshed) {
          Log.dev_log("--> Session Reloaded...");
          // be aware, we have hardcoded the refresh token lifefpans in to their est. values..
          // update the redux state to include the new auth state and auth data...

          // 5 minutes = 5 * 60 seconds = 300 seconds.
          // 1800 seconds is 30 minutes

          store.dispatch({
            type: "AUTH_UPDATE",
            payload: {
              is_authenticated: true,
              auth_data: {
                access_token: responseData.tokens.access_token,
                access_token_lifespan: 300,
                access_token_last_updated: new Date(),

                refresh_token_lifespan: 1800,
                refresh_token_last_updated: new Date(),
                refresh_token: responseData.tokens.refresh_token,
                token_type: responseData.tokens.token_type
              }
            }
          });

          // allow the API requests to execute (with a 500MS delay for store to update.)
          setTimeout(() => {
            store.dispatch({
              type: "REFRESH_UPDATE",
              payload: false
            });
          }, 500);
      }
      else {
        // the refresh process could not complete, so we will sign the user out...
        store.dispatch({
          type: "AUTH_UPDATE",
          payload: {
            is_authenticated: false,
            auth_data: {}
          }
        });

        // remove the block on API requests.
        store.dispatch({
          type: "REFRESH_UPDATE",
          payload: false
        });

        // redirect but prevent a redirect loop...
        // if(window.location.pathname !== "/auth/login") {
        //   window.location.assign('/auth/login');
        // }
        Authentication_Service.redirect_to_auth_loop_safe();
      }

      // if (responseData.token_valid) {
      //   // Token is valid
      //   Log.dev_log(responseData);
        
      // } else {
      //   // Token is invalid
      //   Log.dev_log(responseData);

      // }
    } catch (error) {
      console.error('Failed to refresh token:', error);

      // the refresh process could not complete, so we will sign the user out...
      store.dispatch({
        type: "AUTH_UPDATE",
        payload: {
          is_authenticated: false,
          auth_data: {}
        }
      });

      // remove the block on API requests...
      store.dispatch({
        type: "REFRESH_UPDATE",
        payload: false
      });

      // redirect but prevent a redirect loop...
      // if(window.location.pathname !== "/auth/login") {
      //   window.location.assign('/auth/login');
      // }
      Authentication_Service.redirect_to_auth_loop_safe();
    }
  }

  /**
   * This static method initiates a cycle to refresh the authentication token.
   * It immediately attempts to refresh the authentication token and then sets up a loop to refresh it periodically before it expires.
   * 
   * It retrieves the authentication data from the Redux store and calculates the time until the token expires.
   * A timeout is set to refresh the token 2 minutes before it expires.
   * 
   * If the user is not authenticated, the refresh cycle does not continue.
   * 
   * @returns A promise that resolves to void.
   * @throws An error if any issues occur during the refresh process.
  */
  public static async refresh_authentication_token_cycle(): Promise<void> {
    try {
      this.refresh_authentication_token();

      // get the token from the redux state...
      const reduxState = store.getState();
      if(reduxState.global_auth.is_authenticated) {
        const authData = reduxState.global_auth.auth_data;
        const accessTokenExpTime = authData.access_token_last_updated;
        
        // loop every minute until we need to refresh the access token (2 mins before exp..)
        const currentTime = Date.now();
        const tokenLastUpdated = authData.access_token_last_updated;
        const expiresIn = authData.access_token_lifespan * 1000; // Convert to milliseconds
      
        const timeUntilExpiration = (new Date(tokenLastUpdated).getTime() + expiresIn) - currentTime;
        const refreshBuffer = 120000; // Refresh 1 minute before expiration
      
        Log.dev_log("--> Init refresh token cycle...");
        setTimeout(async () => {
          Log.dev_log("REFRESH NOW");
          await this.refresh_authentication_token();

        }, timeUntilExpiration - refreshBuffer);

      }
      else {
        // there is no need for us to continue since we do not have a token...
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * This static method destroys the current session and redirects the user to the authentication page.
   * It updates the Redux store to reflect the unauthenticated state and clears the authentication data.
   * After a short delay, it logs the user out using Keycloak and redirects to the authentication page in a loop-safe manner.
   * 
   * @returns A promise that resolves to a boolean indicating whether the session was successfully destroyed.
   * @throws An error if any issues occur during the session destruction process.
  */
  public static async destroy_session_redirect(): Promise<boolean> {
    try {
      const keycloak = new Keycloak_Env().get_instance();

      store.dispatch({
        type: "AUTH_UPDATE",
        payload: {
          is_authenticated: false,
          auth_data: {}
        }
      });

      setTimeout(() => {
        Log.dev_log(keycloak);
        keycloak.logout({
          // redirectUri: "/auth/login"
        });
      }, 850);

      setTimeout(() => {
        // window.location.assign("/auth/login");
        this.redirect_to_auth_loop_safe();
      }, 500);

      return true;
    }
    catch(error) {
      console.error(error);
      return false;
    }
  }

  /**
   * This static method attempts to verify the session using the refresh token.
   * It blocks requests to the API while the refresh process is executing.
   * If the refresh token is available, it sends a request to the backend to refresh the access token.
   * If the token is refreshed successfully, it updates the Redux store with the new authentication data and removes the block on API requests.
   * If the refresh process fails, it signs the user out and removes any blocks on API requests.
   * 
   * @returns A promise that resolves to a boolean indicating whether the session was successfully verified with the refresh token.
   * @throws An error if any issues occur during the token refresh process.
  */
  public static async verify_with_refresh_token(): Promise<boolean> {
    try {
      Log.dev_log("--> Attempting to verify session with refresh token...");

      // block requests to the API while this is execitung...
      store.dispatch({
        type: "REFRESH_UPDATE",
        payload: true
      });

      const reduxState = store.getState();
      const accessToken = reduxState.global_auth.auth_data.access_token;
      const refreshToken = reduxState.global_auth.auth_data.refresh_token;

      if(typeof refreshToken !== "undefined") {
        const apiServer = env.default.API_REMOTE_SERVER;
        const response = await axios.post(
          apiServer + "v2/token/refresh",
          {
            refresh_token: refreshToken,
            access_token: accessToken,
            client_id: env.default.KEYCLOAK_CLIENT_ID
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
          }
        );
  
        const responseData = response.data.response_data;
        if(responseData.token_refreshed) {
            Log.dev_log("--> Session Reloaded with refresh token...");
            // be aware, we have hardcoded the refresh token lifefpans in to their est. values..
            // update the redux state to include the new auth state and auth data...
  
            // 5 minutes = 5 * 60 seconds = 300 seconds.
            // 1800 seconds is 30 minutes
  
            store.dispatch({
              type: "AUTH_UPDATE",
              payload: {
                is_authenticated: true,
                auth_data: {
                  access_token: responseData.tokens.access_token,
                  access_token_lifespan: 300,
                  access_token_last_updated: new Date(),
  
                  refresh_token_lifespan: 1800,
                  refresh_token_last_updated: new Date(),
                  refresh_token: responseData.tokens.refresh_token,
                  token_type: responseData.tokens.token_type
                }
              }
            });

            // remove the block on the API service requests.
            store.dispatch({
              type: "REFRESH_UPDATE",
              payload: false
            });
  
            return true;
  
        }
        else {
          // the refresh process could not complete, so we will sign the user out...
          store.dispatch({
            type: "AUTH_UPDATE",
            payload: {
              is_authenticated: false,
              auth_data: {}
            }
          });

          // remove any blocks to the API service.
          store.dispatch({
            type: "REFRESH_UPDATE",
            payload: false
          });
  
          // redirect but prevent a redirect loop...
          return false;
        }
  
        // if (responseData.token_valid) {
        //   // Token is valid
        //   Log.dev_log(responseData);
          
        // } else {
        //   // Token is invalid
        //   Log.dev_log(responseData);
  
        // }
      }
      else {
        // we do not have a refresh token... so no auth can consinue... 
        store.dispatch({
          type: "AUTH_UPDATE",
          payload: {
            is_authenticated: false,
            auth_data: {}
          }
        });

        // remove the any blocks that might exist.
        store.dispatch({
          type: "REFRESH_UPDATE",
          payload: false
        });

        Log.dev_log("--> No refresh token found in redux store...");
        return false;
      }
    } catch (error) {
      console.error('Failed to refresh token:', error);

      // the refresh process could not complete, so we will sign the user out...
      store.dispatch({
        type: "AUTH_UPDATE",
        payload: {
          is_authenticated: false,
          auth_data: {}
        }
      });

      return false;
    }
  }

  /**
   * This static method verifies the authentication token by sending a request to the backend.
   * It retrieves the access token from the Redux store and attempts to verify it with the backend.
   * If the token is valid, it returns true.
   * If the token is invalid, it attempts to verify the token using the cached refresh token.
   * If the verification with the refresh token is successful, it returns true.
   * If both verifications fail, it returns false.
   * 
   * @returns A promise that resolves to a boolean indicating whether the authentication token is valid.
   * @throws An error if any issues occur during the token verification process.
  */
  public static async verify_authentication_token(): Promise<boolean> {
    const keycloak = new Keycloak_Env().get_instance(); 
    const reduxState = store.getState();

    const keycloakUrl = env.default.KEYCLOAK_URL;
    const keycloakRealm = env.default.KEYCLOAK_REALM;
    const keycloakClientId = env.default.KEYCLOAK_CLIENT_ID;

    const accessToken = reduxState.global_auth.auth_data.access_token;

    try {
      const apiServer = env.default.API_REMOTE_SERVER;
      const response = await axios.post(
        apiServer + "v2/token/verify",
        {
          access_token: accessToken
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }
      );

      const responseData = response.data.response_data;
  
      if (responseData.token_valid) {
        // Token is valid
        return true;
      } else {
        // last ditch effort... See if the cached refresh token is still valid (it should be for 30 mins since last issue)
        const verifyWithRefreshToken = await Authentication_Service.verify_with_refresh_token();
        if(verifyWithRefreshToken) {
          return true;
        }
        else {
          return false;
        }

      }
    } catch (error) {
      Log.dev_error('Failed to validate token:' + error);
      
      // last ditch effort... See if the cached refresh token is still valid (it should be for 30 mins since last issue)
      const verifyWithRefreshToken = await Authentication_Service.verify_with_refresh_token();
      if(verifyWithRefreshToken) {
        return true;
      }
      else {
        return false;
      }
    }

    return false;

    // // check that we have an access token to veriy
    // if(reduxState.global_auth.is_authenticated) {
    //   if(reduxState.global_auth.auth_data.access_token !== "") {
    //     const accessToken = reduxState.global_auth.auth_data.access_token;

    //     keycloak.init({ token: accessToken}).then(authenticated => {          
    //       Log.dev_log(authenticated);
    //       if (authenticated) {
    //         const keycloakInstance = Keycloak_Env;
    //         const accessToken = keycloak.token;

    //         return true;
    //       }
    //       else {
    //         // update the redux state to include the new auth state and auth data...
    //         // store.dispatch({
    //         //   type: "AUTH_UPDATE",
    //         //   payload: {
    //         //     is_authenticated: false,
    //         //     auth_data: {}
    //         //   }
    //         // });
    
    //         return false;
    //       }
    //     });

    //   }
    //   else {
    //     // access token in redux state is null, so not valid..
    //     return false;
    //   }

    // }
    // else {
    //   return false;
    // }

    // default return value...
    return false;
  }

  /**
   * This static method handles the local redirect for Single Sign-On (SSO) authentication.
   * It checks the global authentication state from the Redux store to determine if the user is authenticated.
   * If the user is authenticated, it verifies the validity of the authentication token.
   * If the token is invalid, it updates the Redux state to reflect the unauthenticated status, ensures no API request blocks are in place, and redirects the user to the authentication page in a loop-safe manner.
   * If the token is valid, it allows the user to proceed.
   * If the user is not authenticated, it redirects the user to the authentication page in a loop-safe manner.
   * 
   * @returns A promise that resolves to void.
   * @throws An error if any issues occur during the authentication process.
  */
  public static async authentication_sso_local_redirect(): Promise<void> {
    try {
      const reduxState = store.getState();
      if(reduxState.global_auth.is_authenticated) {
        const accessToken = reduxState.global_auth.auth_data.access_token;
        // Log.dev_log(accessToken);
        const authTokenValid = await Authentication_Service.verify_authentication_token();
        if(!authTokenValid) {

          // update the redux state to reflect this...
         await store.dispatch({
            type: "AUTH_UPDATE",
            payload: {
              is_authenticated: false,
              auth_data: {}
            }
          })

          // ensure there are no API request blocks in place...
          store.dispatch({
            type: "REFRESH_UPDATE",
            payload: false
          });
        
          // redirect but prevent a redirect loop...
          // if(window.location.pathname !== "/auth/login") {
          //   window.location.assign('/auth/login');
          // }
          // else {
          // }
          Authentication_Service.redirect_to_auth_loop_safe();
        }
        else {
          // the token is valid... give 500ms and remove the loader...

        }
          
      }
      else {
        // not authenticated...
        // redirect but prevent a redirect loop...
        // if(window.location.pathname !== "/auth/login") {
        //   window.location.assign('/auth/login');
        // }
        // else {
        //   // remove global loader...
        // } 
        Authentication_Service.redirect_to_auth_loop_safe();

      }

      // // verify the authentication token...
      // const authenticationTokenValid = Authentication_Service.verify_authentication_token();
      // Log.dev_log(authenticationTokenValid);
      // if(authenticationTokenValid) {
      //   // nothing to be done...

      // }
      // else {
      //   // redirect but prevent a redirect loop...
      //   if(window.location.pathname !== "/auth/login") {
      //     // window.location.assign('/auth/login');
      //   }
      // }

    }
    catch(error) {
      // TOOD: turn this into a global error...
      throw error;
    }
  }

  /**
    * This static method handles the Single Sign-On (SSO) redirect for authentication.
    * It initializes the Keycloak instance and attempts to authenticate the user.
    * If the user is authenticated, it logs a message indicating successful authentication.
    * If authentication fails or an error occurs during Keycloak initialization, it logs an error message.
  */
  public static authentication_sso_redirect(): void {
    const keycloak = new Keycloak_Env().get_instance();
    keycloak.init({ onLoad: 'login-required' }).then((authenticated: boolean) => {
      if (authenticated) {
        Log.dev_log('User is authenticated');
      } else {
        console.error('Authentication failed');
      }
    }).catch((error) => {
      console.error('Keycloak initialization error', error);
    });
  }

  public static async authentication_request(username: string, password: string): Promise<Authentication_Request_Response> {
    try {
      // remove any API blocks that may be in place from a previous session...
      store.dispatch({
        type: "REFRESH_UPDATE",
        payload: false
      });

      const keycloak = new Keycloak_Env().get_instance();

      const keycloakUrl = env.default.KEYCLOAK_URL;
      const realm = env.default.KEYCLOAK_REALM;
      const clientId = env.default.KEYCLOAK_CLIENT_ID;
      
      const params = new URLSearchParams();
      params.append('client_id', clientId);
      params.append('grant_type', 'password');
      params.append('username', username);
      params.append('password', password);

      try {
        const response = await fetch(`${keycloakUrl}/realms/${realm}/protocol/openid-connect/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params.toString(),
        });

        if (response.ok) {
          const data = await response.json();
          keycloak.token = data.access_token;
          keycloak.refreshToken = data.refresh_token;
          
          // update the redux state to include the new auth state and auth data...
          store.dispatch({
            type: "AUTH_UPDATE",
            payload: {
              is_authenticated: true,
              auth_data: {
                access_token: data.access_token,
                access_token_lifespan: data.expires_in,
                access_token_last_updated: new Date(),

                refresh_token_lifespan: data.refresh_expires_in,
                refresh_token_last_updated: new Date(),
                refresh_token: data.refresh_token,
                token_type: data.token_type
              }
            }
          });

          // ensure there are no API blocks in place...
          store.dispatch({
            type: "REFRESH_UPDATE",
            payload: false
          });

          // Optionally, you can set the tokens in your app state or local storage
          return {
            auth_result: true,
            has_error: false
          };

        } else {
          // update the redux state...
          store.dispatch({
            type: "AUTH_UPDATE",
            payload: {
              is_authenticated: false,
              auth_data: {}
            }
          });

          return {
            auth_result: false,
            has_error: false
          }

        }
      } catch (error) {
        // update the redux state...
        store.dispatch({
          type: "AUTH_UPDATE",
          payload: {
            is_authenticated: false,
            auth_data: {}
          }
        });

        return {
          auth_result: false,
          has_error: true
        };
      }

      // default return type...
      return {
        auth_result: false, 
        has_error: true
      };

    }
    catch(exception) {
      return {
        auth_result: false,
        has_error: true
      };
    }
  }

  /**
   * Initiates the account recovery process for a user based on their email address.
   * 
   * This function sends a request to the backend API to start the account recovery process.
   * If the request is successful, it returns true. Otherwise, it throws an error.
   * 
   * @param {string} email_address - The email address of the account to be recovered.
   * 
   * @returns {Promise<boolean>} - A promise that resolves to true if the account recovery
   * process is successfully initiated.
   * 
   * @throws {Error} - Throws an error if the account recovery process could not be started
   * or if there is any error during the request.
  */
  public static async recover_account(email_address: string): Promise<boolean> {
    try {    
      const request = new Backend_Request({
        endpoint: "v2/account/recovery",
        requestBody: { account_username: email_address },
        verbose: false
      });
  
      const result = await request.perform();
  
      if (result.is_success) {
        return true; // Return true on success
      } else {
        throw new Error("Could not start account recovery.");
      }
    } catch (error) {
      throw error; // Re-throw the error for further handling if necessary
    }
  }

  /**
   * This static method validates a given recovery code by sending a request to the backend.
   * It constructs a request with the recovery code and performs the request.
   * If the request is successful and the recovery code is valid, it returns true.
   * If the recovery code is invalid or the request fails, it returns false or throws an error respectively.
   * 
   * @param recovery_code - The recovery code to be validated.
   * @returns A promise that resolves to a boolean indicating whether the recovery code is valid.
   * @throws An error if the request to validate the recovery code fails.
  */
  public static async validate_recovery_code(recovery_code: string): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/account/recovery/validate-code",
        requestBody: { recovery_code },
        verbose: false
      });
  
      const result = await request.perform();
  
      if (result.is_success) {
        if(result.response_data.recovery_code_valid) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        throw "Could not validate recovery code.";
      }

    }
    catch(error) {
      throw error;
    }
  }

  /**
   * Recover account and reset password.
   *
   * This method attempts to reset the account password using a provided recovery code and new password. 
   * It sends a request to the backend with the recovery details and returns a boolean indicating whether 
   * the account recovery and password reset was successful.
   *
   * @param {string} recovery_code - The recovery code provided for account recovery.
   * @param {string} new_password - The new password to set for the account.
   * @returns {Promise<boolean>} - A promise that resolves to true if the account was successfully recovered and the password was reset, otherwise false.
   * @throws Will throw an error if the recovery code could not be validated or if the account password could not be reset.
  */
  public static async recover_account_reset_password(recovery_code: string, new_password: string): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/account/recovery/reset-password",
        requestBody: { recovery_code, new_password },
        verbose: false
      });
  
      const result = await request.perform();
  
      if (result.is_success) {
        if(result.response_data.password_updated) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        throw "Could not validate recovery code.";
      }

    }
    catch(error) {
      throw "Could not reset account password.";
    }
  }

  /**
   * Set the authentication token.
   *
   * This method sets the authentication token for the current session.
   *
   * @param {string} token - The token to set for authentication.
   */
  public static set_authentication_token(token: string) {
      // Implementation to set the authentication token
  }

  /**
   * Set the authentication status.
   *
   * This method sets the authentication status indicating whether the user is authenticated or not.
   *
   * @param {boolean} authenticated - The authentication status to set.
   */
  public static set_authenticated(authenticated: boolean) {
      // Implementation to set the authentication status
  }

}