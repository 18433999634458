import React from 'react';
import { Stack, StackItem, Text, IconButton, TextField, Modal, DefaultButton, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import {ReactComponent as BrandLogoSvg} from './../../../assets/branding/brand.svg';
import { Button, Spinner, ButtonProps, Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, Field, Input, MessageBar, ProgressBar } from "@fluentui/react-components";

import backgroundImage from './../../../assets/backgrounds/default.jpg';
import Global_Error from '../../../lib/GlobalError/GlobalError';
import Authentication_Service from '../../../lib/Authentication/AuthenticationService';
import Authentication_Request_Response from '../../../lib/Authentication/AuthenticationService';
import Spacer from '../../../components/Spacer/Spacer';
import ViewLoader from '../../../components/ViewLoader/ViewLoader';
import { CheckmarkCircle32Regular, CheckmarkCircle48Regular } from '@fluentui/react-icons';

export default class RecoveryView extends React.Component {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    this.styles = {
      wrapper: {
        width: '100vw',
        height: '100vh',

        backgroundImage: `url(${backgroundImage})`,
        backgroundSize:  'cover',
        overflow:        'hidden',
        justifyContent:  'flex-end',

        zIndex: 999999
      },

      loginWrapper: {
        
      },

      logo: {
        marginLeft: -310
      },

      pwResetModalContainer: {
        width: 500,
        padding: 25,
        paddingTop: 0
      }
    }

    this.state = {
      displayPasswordResetModal: false,
      is_submitting: false,

      username: "",
      password: "",
      confirm_password: "",

      has_recovery_code: false,
      passwordStrength: 0,
      passwordStrengthLabel: "",

      recovery_code: "",
      new_passwords_valid: false,
      password_has_reset: false
    };
  }

  /**
   * Validate the recovery code.
   *
   * This method validates the recovery code by sending it to the authentication service.
   * If the validation is successful, it updates the state with the validation result.
   * If an error occurs during validation, a global error is displayed to the user.
   *
   * @returns {Promise<void>} - A promise that resolves when the validation process is complete.
   * @throws Will display a global error if the recovery code validation fails.
  */
  private async validate_recovery_code(): Promise<void> {
    try {
      const state = this.state as any;
      console.log(state.recovery_code);

      const validateRecoveryCodeResponse = await Authentication_Service.validate_recovery_code(state.recovery_code);
      this.setState({
        is_validating_recovery_code: false,
        recovery_code_valid:         validateRecoveryCodeResponse
      });

    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Could not recover account",
        error_content: "There seems to be an issue connecting your request to our authentication service. Please try again, if the issue persists then please contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
        this.setState({
          is_submitting: false
        });
      });
    }
  }

  /**
   * This method is called immediately after the component is mounted.
   * It checks the URL parameters for a recovery code and updates the state accordingly.
   */
  public componentDidMount(): void {
    const params = new URLSearchParams(window.location.search);
    const value = params.get('rid');
    if(value) {
      this.setState({
        has_recovery_code:           true,
        recovery_code:               value,
        is_validating_recovery_code: true
      }, () => this.validate_recovery_code());
    }
  }

  /**
   * This method handles the recovery request process.
   * It sets the submitting state to true, then attempts to recover the account using the username from the state.
   * If the recovery fails, it displays an error message to the user. Otherwise, it updates the state to indicate that the recovery has started.
   */
  public recovery_request = async (): Promise<void> => {
    try {
      this.setState({
        is_submitting: true
      });
  
      const state = this.state as any;
      const recoveryResponse = await Authentication_Service.recover_account(state.username);
  
      if(!recoveryResponse) {
        const globalError = new Global_Error()
        globalError.display_user_visible_error({
          error_title: "Could not recover account",
          error_content: "There seems to be an issue connecting your request to our authentication service. Please try again, if the issue persists then please contact support@121digital.co.uk"
        });
  
        globalError.dialog_events.on("dialog_close", () => {
          globalError.dialog_events.removeAllListeners();
          this.setState({
            is_submitting: false
          });
        });
      }
      else {
        this.setState({
          is_submitting:    false,
          recovery_started: true
        });
      }

    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Could not recover account",
        error_content: "There seems to be an issue connecting your request to our authentication service. Please try again, if the issue persists then please contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
        this.setState({
          is_submitting: false
        });
      });
    }
  }

  /**
   * This method calculates the strength of the given password.
   * It returns an object containing the strength (a number between 0 and 1) and a label describing the strength.
   *
   * @param password - The password to evaluate.
   * @returns An object with the strength and label of the password.
   */
  private calculatePasswordStrength(password: string): { strength: number, label: string } {
    let strength = 0;
    let label = "Weak";
    if(password.length >= 8) strength += 1;
    if(/[A-Z]/.test(password)) strength += 1;
    if(/[a-z]/.test(password)) strength += 1;
    if(/[0-9]/.test(password)) strength += 1;
    if(/[^A-Za-z0-9]/.test(password)) strength += 1;

    if(strength === 5) label = "Very Strong";
    else if(strength >= 3) label = "Strong";
    else if(strength >= 2) label = "Medium";

    return { strength: strength / 5, label };
  }

  /**
   * Event handler for when the password input changes.
   * It updates the state with the new password and its calculated strength.
   *
   * @param event - The change event from the password input field.
   */
  private handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const { strength, label } = this.calculatePasswordStrength(password);
    this.setState({
      password,
      passwordStrength: strength,
      passwordStrengthLabel: label
    }, this.on_password_input);
  }

  /**
   * Event handler for when the confirm password input changes.
   * It updates the state with the new confirm password value.
   *
   * @param event - The change event from the confirm password input field.
   */
  private handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirm_password: event.target.value
    }, this.on_password_input);
  }

  /**
   * This method checks if the passwords match and updates the state.
   */
  private on_password_input = () => {
    const state = this.state as any;
    if(state.password === state.confirm_password) {
      this.setState({
        new_passwords_valid: true
      });
    }
    else {
      this.setState({
        new_passwords_valid: false
      });
    }
  }

  /**
   * Confirm reset password.
   *
   * This method attempts to reset the account password using the provided recovery code and new password.
   * If successful, it logs a success message. If an error occurs, a global error is displayed to the user.
   *
   * @returns {Promise<void>} - A promise that resolves when the password reset process is complete.
   * @throws Will display a global error if the password reset fails.
  */
  private confirm_reset_password  = async (): Promise<void> => {
    try {
      const state = this.state as any;
      const recoveryCode: string = state.recovery_code;
      const newPassword: string = state.password;
      const resetPasswordResponse = await Authentication_Service.recover_account_reset_password(recoveryCode, newPassword);

      if(resetPasswordResponse) {
        this.setState({
          password_has_reset: true
        });

      }
      else {
        throw "Could not reset your account password.";
      }
    }
    catch(error) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Could not reset password",
        error_content: "There seems to be an issue connecting your request to our authentication service. Please try again, if the issue persists then please contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
        this.setState({
          is_submitting: false
        });
      });
    }
  }

  /**
   * This method renders the component.
   * It conditionally renders either the password reset form or the email recovery request form based on the presence of a recovery code.
   * 
   * If a recovery code is present, it renders the password reset form with fields for the new password and confirmation, 
   * along with a password strength indicator and reset/cancel buttons.
   * 
   * If no recovery code is present, it renders the email recovery request form with a field for the email address and instructions 
   * for requesting a password reset link, along with recover/cancel buttons.
  */
  public render() {
    const state = this.state as any;

    return (
      <div style={this.styles.wrapper}>
        {state.has_recovery_code? (
          <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 400, margin: '0 auto', marginTop: '35vh', padding: 25, backgroundColor: "white", borderRadius: 4} }}>
            <StackItem>
              <BrandLogoSvg height={70} style={this.styles.logo} />
            </StackItem>

            {state.is_validating_recovery_code? (
              <ViewLoader height='175px' message='Loading, Please Wait...'></ViewLoader>
            ): (
              <div> 
                {!state.recovery_code_valid? (
                  <StackItem>
                    <h2>Link Expired</h2>
                    <Text>
                      You cannot reset your password using this link since it has
                      expired. The link provided during the account recovery process
                      is only valid for 5 minutes.
                    </Text>
                    <Spacer size='15px' />
                    <Link onClick={() => window.location.assign('/auth/recovery')}>
                      Restart account recovery
                    </Link>
                  </StackItem>
                ) : (
                  <div>
                    {state.password_has_reset? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CheckmarkCircle48Regular color='green' />
                        <h3>Your Password Has Been Updated</h3>
                        <Text style={{textAlign: 'center', marginTop: -12}}>You can now login with your new password.</Text>
                        <Spacer size='25px' />
                        <Button onClick={() => window.location.assign('/auth/login')} appearance='primary'>Return To Login</Button>
                      </div>
                    ): (
                      <div>
                        <StackItem>
                          <Field label={"New Password"}>
                            <Input
                              type="password"
                              disabled={state.is_submitting}
                              size='large'
                              value={state.password}
                              onChange={this.handlePasswordChange}
                            />
                          </Field>
                      </StackItem>
        
                      <StackItem>
                        <Field label={"Confirm New Password"}>
                          <Input
                            type='password'
                            disabled={state.is_submitting}
                            size='large'
                            value={state.confirm_password}
                            onChange={this.handleConfirmPasswordChange}
                          />
                        </Field>
                      </StackItem>
        
                      {state.password? (
                        <StackItem>
                          <Spacer size='15px' />
                          <ProgressBar thickness='large' value={state.passwordStrength} />
                          <small>Password Strength: {state.passwordStrengthLabel}</small>
                        </StackItem>
                      ): (<></>)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {state.password_has_reset? (
              <></>
            ) : (
              <StackItem>
                <Spacer size='25px' />
                <Button disabled={!state.new_passwords_valid} onClick={this.confirm_reset_password} appearance='primary'>
                  Reset Password
                </Button>
                <Button onClick={() => {
                    window.location.assign('/auth/login');
                  }} type='button' style={{marginLeft: 15}}>Cancel</Button>
              </StackItem>
            )}

          </Stack>
        ): (
          <form>
            <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 400, margin: '0 auto', marginTop: '35vh', padding: 25, backgroundColor: "white", borderRadius: 4} }}>

              <StackItem>
                <BrandLogoSvg height={70} style={this.styles.logo} />
              </StackItem>

              <StackItem>
                <Field label={"Email Address"}>
                  <Input
                    disabled={state.is_submitting}
                    size='large'
                    value={state.username}
                    onChange={(event) => {
                      this.setState({
                        username: event.target.value
                      });
                    }}
                  />
                </Field>
              </StackItem>
              <StackItem>
                <span>
                  Enter your account email address, and a password reset link
                  will be sent. Follow the instructions on the password reset
                  email to continue.
                </span>

                {state.recovery_started? (
                  <div>
                    <Spacer size='15px' />
                    <Text color='green'><b>An email has been sent to your account if your username exists in our records.</b></Text>
                  </div>
                ): (<div></div>)}
              </StackItem>
              <StackItem>
                <Spacer size='25px' />

                {state.recovery_started? (
                  <Button onClick={() => window.location.assign('/auth/login')} appearance='primary'>
                    Ok  
                  </Button>
                ): (
                  <Button onClick={this.recovery_request} appearance='primary'>
                    {state.is_submitting? (
                      <Spinner size='tiny' />
                    ): (
                      "Recover Account"
                    )}
                  </Button>
                )}
                <Button onClick={() => {
                  window.location.assign('/auth/login');
                }} type='button' style={{marginLeft: 15}}>Cancel</Button>
              </StackItem>
            </Stack>
          </form>
        )}
      </div>
    );
  }

}