import React from 'react';
import { Stack, StackItem, Text, IconButton, TextField, Modal, DefaultButton, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import {ReactComponent as BrandLogoSvg} from './../../../assets/branding/brand.svg';
import { Button, Spinner, ButtonProps, Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, Field, Input, MessageBar } from "@fluentui/react-components";

import backgroundImage from './../../../assets/backgrounds/default.jpg';
import Global_Error from '../../../lib/GlobalError/GlobalError';
import Authentication_Service from '../../../lib/Authentication/AuthenticationService';
import Authentication_Request_Response from '../../../lib/Authentication/AuthenticationService';
import Spacer from '../../../components/Spacer/Spacer';

export default class RecoveryResetView extends React.Component {

  private styles: any = {};

  constructor(props: any) {
    super(props);

    this.styles = {
      wrapper: {
        width: '100vw',
        height: '100vh',

        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        overflow: 'hidden',
        justifyContent: 'flex-end',

        zIndex: 999999
      },

      loginWrapper: {
        
      },

      logo: {
        marginLeft: -310
      },

      pwResetModalContainer: {
        width: 500,
        padding: 25,
        paddingTop: 0
      }
    }

    this.state = {
      displayPasswordResetModal: false,
      is_submitting: false,

      username: "",
      password: ""
    };
  }

  public recovery_request = async (): Promise<void> => {
    this.setState({
      is_submitting: true
    });

    // const globalError = new Global_Error()
    // globalError.display_user_visible_error({
    //   error_title: "Invalid Login",
    //   error_content: "Your username or password is incorrect. Please try again. If you have forgotten your password, consider resetting it."
    // });

    // globalError.dialog_events.on("dialog_close", () => {
    //   this.setState({
    //     is_submitting: false
    //   });
    //   globalError.dialog_events.removeAllListeners();
    // });
    
    const state = this.state as any;
    const recoveryResponse = await Authentication_Service.recover_account(state.username);

    // check if we have a server related issue...
    if(!recoveryResponse) {
      const globalError = new Global_Error()
      globalError.display_user_visible_error({
        error_title: "Could not recover account",
        error_content: "There seems to be an issue connecting your request to our authentication service. Please try again, if the issue persists then please contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
        this.setState({
          is_submitting: false
        });
      });
    }
    else {
      // free up the form if bad request...        
      this.setState({
        is_submitting:    false,
        recovery_started: true
      });
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <div style={this.styles.wrapper}>
        <form>
          <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 400, margin: '0 auto', marginTop: '35vh', padding: 25, backgroundColor: "white", borderRadius: 4} }}>
            <StackItem>
              <BrandLogoSvg height={70} style={this.styles.logo} />
            </StackItem>

            <StackItem>
              <Field label={"Email Address"}>
                <Input
                  disabled={state.is_submitting}
                  size='large'
                  value={state.username}
                  onChange={(event) => {
                    this.setState({
                      username: event.target.value
                    });
                  }}
                />
              </Field>
              <Field label={"Email Address"}>
                <Input
                  disabled={state.is_submitting}
                  size='large'
                  value={state.username}
                  onChange={(event) => {
                    this.setState({
                      username: event.target.value
                    });
                  }}
                />
              </Field>
            </StackItem>
            <StackItem>
              {state.recovery_started? (
                <div>
                  <Spacer size='15px' />
                  <Text color='green'><b>An email has been sent to your account if your username exists in our records.</b></Text>
                </div>
              ): (<div></div>)}
            </StackItem>
            <StackItem>
              <Spacer size='25px' />

              {state.recovery_started? (
                <Button onClick={() => window.location.assign('/auth/login')} appearance='primary'>
                  Ok  
                </Button>
              ): (
                <Button onClick={this.recovery_request} appearance='primary'>
                  {state.is_submitting? (
                    <Spinner size='tiny' />
                  ): (
                    "Recover Account"
                  )}
                </Button>
              )}
              <Button onClick={() => {
                window.location.assign('/auth/login');
              }} type='button' style={{marginLeft: 15}}>Cancel</Button>
            </StackItem>
          </Stack>
        </form>

        {/* <Modal
            isOpen={(this.state as any).displayPasswordResetModal}
            onDismiss={this.hidePasswordResetModal}
            isBlocking={false}
            containerClassName="modalContainer"
        > 

            <div style={this.styles.pwResetModalContainer}>
                <h2>Reset your account password</h2>

                <Button appearance='primary'>Submit</Button>
                <Button style={{marginTop: 25, marginLeft: 10}} onClick={this.hidePasswordResetModal}>
                  Cancel
                </Button>
            </div>
        </Modal> */}
      </div>
    );
  }

}