import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, CommandBar, IBreadcrumbItem } from '@fluentui/react';
import { Button, Card, CardHeader, Link, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Text, Toolbar, ToolbarButton } from '@fluentui/react-components';
import Spacer from '../../components/Spacer/Spacer';
import { Add24Regular, CheckmarkCircle16Filled } from '@fluentui/react-icons';
import DomainsToolbar from '../../components/Domains/DomainsToolbar';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import Global_Error from '../../lib/GlobalError/GlobalError';
import Domain_Name, { Generic_Domain_Name } from '../../lib/DomainName/DomainName';

import {ReactComponent as reg121_brand} from '../../assets/branding/reg121_brand_2025.svg';
import live_dns_png from './../../assets/121_live_dns.png';

export default class DomainsView extends React.Component {

  private breadcrumbItems: IBreadcrumbItem[];
  private styles: any = {};
  private commandBarItems: Array<any>;

  constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => (this.props as any).navigate('/') },
      { text: 'Domain Names', key: 'manage_domain', isCurrentItem: true },
    ];

    // define view styles...
    this.styles = {
      commandBar: {
        borderTopStyle: "solid",
        width: "100%"
      }
    };

    // define initial state...
    this.state = {
      is_loading: true,
      domain_names: []
    };

    this.commandBarItems = [
      {
        key: 'newItem',
        name: 'New',
        iconProps: { iconName: 'Add' },
        onClick: () => alert('New item clicked')
      },
      {
        key: 'upload',
        name: 'Upload',
        iconProps: { iconName: 'Upload' },
        onClick: () => alert('Upload clicked')
      },
      {
        key: 'share',
        name: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: () => alert('Share clicked')
      }
      // Add more commands as needed
    ];

    // load the view data...
    this.load_data();
  }

  private load_data = async (): Promise<void> => {
    try {
      const domainNames = await Domain_Name.get_domain_names();
      this.setState({
        is_loading:   false,
        domain_names: domainNames
      });

    }
    catch(error) {
      const globalError = new Global_Error();
      globalError.display_user_visible_error({
        error_title: "Cannot Load Domain Names",
        error_content: "There seems to be an issue loading your domain names. Please refresh the page and try again or contact support@121digital.co.uk"
      });

      globalError.dialog_events.on("dialog_close", () => {
        globalError.dialog_events.removeAllListeners();
      });
    }
  }

  public render() {
    const state: any = this.state as any;

    return (
      <SafeArea>
        {state.is_loading? (
          <ViewLoader message='Loading domain names. Please Wait...' />
        ): (
          <div style={{width: "100%"}}>
          <Breadcrumb
            style={{marginLeft: -6}}
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />
          <h1>Manage Your Domain Names</h1>

          {/* <Spacer size='5px' /> */}
          {/* <DomainsToolbar /> */}

          <Spacer size='15px'></Spacer>

          <div style={{maxWidth: 1200, marginLeft: 1}}> 
            <Card>
              <CardHeader
                header={<Text weight="semibold">Your Domain Names</Text>}
                description={
                  <></>
                }
                action={
                  <></>
                }
              />

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell style={{paddingLeft: 0}}><b>Domain Name</b></TableHeaderCell>
                    <TableHeaderCell><b>Expiry Date</b></TableHeaderCell>
                    <TableHeaderCell><b>Date Created</b></TableHeaderCell>
                    <TableHeaderCell><b>Nameserver Setting</b></TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHeader>

                {state.domain_names.map((domainName: Generic_Domain_Name) => {
                  return (
                    <TableRow>
                      <TableCell style={{paddingLeft: 0}}>
                        <Link href={"http://" + domainName} target="_blank" >
                          {domainName.domain_name}
                        </Link>
                      </TableCell>
                      <TableCell>{domainName.renew_date}</TableCell>
                      <TableCell>{domainName.order_date}</TableCell>
                      <TableCell>
                        <CheckmarkCircle16Filled color='#107C10' /> <img height={15} src={live_dns_png} />
                      </TableCell>
                      <TableCell>
                        <Button style={{float: 'right'}} onClick={() => {
                          (this.props as any).navigate('/domains/manage-domain/' + domainName.domain_name);
                        }}>Manage Domain</Button>
                      </TableCell>
                  </TableRow>
                  )
                })}

              </Table>
            </Card>
          </div>

          {/* <div style={this.styles.commandBar}>
            <CommandBar
              items={this.commandBarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
          </div> */}
        </div>
        )}
      </SafeArea>
    )
  }

}