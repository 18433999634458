import Backend_Request from "../Request/BackendRequest";

export interface Billing_Overview {
  total_order_count: number,
  active_order_count: number,
  inactive_order_count: number
};

export interface Billing_Order {
  display_name: string,
  is_cancelled: boolean,
  order_date:   string,
  order_type:   string
};


export default class Billing {

  public static async get_all_orders(): Promise<Array<Billing_Order>> {
    const request = new Backend_Request({
      endpoint: "v2/billing/orders/get",
      requestBody: {},
      verbose: false
    });
    const result = await request.perform();

    if(result.is_success) {
      console.log(result);
      return result.response_data.orders as Array<Billing_Order>;
    }
    else {
      throw "Could not load billing orders...";
    }
  }

  public static async get_billing_overview(): Promise<Billing_Overview> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/billing/overview/get",
        requestBody: {},
        verbose: false
      });
      const result = await request.perform();

      if(result.is_success) {
        const billingReport = result.response_data.billing_report.meta;
      
        return {
          total_order_count: billingReport.total_order_count,
          active_order_count: billingReport.total_active_count,
          inactive_order_count: billingReport.total_inactive_count
        };

      }
      else {
        throw "Cannot load billing overview data...";
      }
    }
    catch(error) {
      throw error;
    }
  }

  public static async account_has_credits(): Promise<boolean> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/billing/has_credits",
        requestBody: {},
        verbose: false
      });
      const result = await request.perform();

      if(result.is_success) {
        return result.response_data.has_credits;

      }
      else {
        throw "Cannot verify if account has credits.";
      }

    }
    catch(error) {
      throw error;
    }
  } 

  public static async get_account_credit_value(): Promise<number> {
    try {
      const request = new Backend_Request({
        endpoint: "v2/billing/credits/get",
        requestBody: {},
        verbose: false
      });
      const result = await request.perform();

      if(result.is_success) {
        return result.response_data.billing_credits;

      }
      else {
        throw "Cannot verify if account has credits.";
      }

    }
    catch(error) {
      throw error;
    }
  } 

}