import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import { Breadcrumb, DefaultButton, IBreadcrumbItem, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { DomainHealthPanel } from '../../components/Domains/DomainHealthPanel';
import { DomainNameserversPanel } from '../../components/Domains/DomainNameserversPanel';
import { DomainDnsPanel } from '../../components/Domains/DomainDnsPanel';
import { DomainNameserverEditPanel } from '../../components/Domains/DomainNameserverEditPanel';
import Spacer from '../../components/Spacer/Spacer';
import { Badge, Button, Card, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from '@fluentui/react-components';

import live_dns_png from './../../assets/121_live_dns.png';
import { Checkmark16Filled, CheckmarkCircle16Filled } from '@fluentui/react-icons';
import ViewLoader from '../../components/ViewLoader/ViewLoader';
import Domain_Name, { Detailed_Domain_Name, Domain_Nameserver } from '../../lib/DomainName/DomainName';
import { Domain } from 'domain';

export default class ManageDomainView extends React.Component {

  private styles: any = {};
  private breadcrumbItems: IBreadcrumbItem[];

  constructor(props: any) {
    super(props);

    // define basic breadcrumb data...
    this.breadcrumbItems = [
      { text: 'Home', key: 'home', onClick: () => window.location.assign("/dashboard") },
      { text: 'Domain Names', key: 'domains', onClick: () => window.location.assign("/domains") },
      { text: 'Manage Domain', key: 'domain', onClick: () => window.location.assign("/domains") },
      { text: '121digital.co.uk', key: 'manage_domain', isCurrentItem: true },
    ];

    // define the views style data...
    this.styles = {
      panelWrapper: {
        display: "flex",
        columnGap: 25,
        marginLeft: 1,

        maxWidth: 1200
      }
    };

    this.state = {
      is_loading: true,
      editNameaervers: false,
      nameservers: [
        // {nameserverRecordName: "Nameserver 1", value: "ns1.121domains.co.uk"},
        // {nameserverRecordName: "Nameserver 2", value: "ns2.121domains.co.uk"},
        // {nameserverRecordName: "Nameserver 3", value: "ns3.121domains.co.uk"},
        // {nameserverRecordName: "Nameserver 4", value: "ns4.121domains.co.uk"}
      ]
    }

    // start the initial data load...
    this.load_data();

  }

  private async load_data(): Promise<void> {
    try {
      const url = window.location.pathname;
      const parts = url.split('/');

      const urlDomainName = parts[parts.length - 1];
      const domainName: Detailed_Domain_Name = await Domain_Name.get_domain_name(urlDomainName); 

      this.breadcrumbItems = [
        { text: 'Home', key: 'home', onClick: () => window.location.assign("/dashboard") },
        { text: 'Domain Names', key: 'domains', onClick: () => window.location.assign("/domains") },
        { text: 'Manage Domain', key: 'domain', onClick: () => window.location.assign("/domains") },
        { text: urlDomainName, key: 'manage_domain', isCurrentItem: true },
      ];

      // build the nameservers array...
      let nameservers = [];
      for(let nameserver in domainName.nameservers) {
        const domainNameserver: Domain_Nameserver = (domainName.nameservers as any)[nameserver];

        nameservers.push({
          nameserverRecordName: domainNameserver.display_name,
          value:                domainNameserver.value
        });
      }

      this.setState({
        is_loading: false,
        domain_name: domainName,
        nameservers: nameservers,

        nameservers_have_updated: true
      });

    }
    catch(error) {
      throw error;
    }
  }

  public render() {
    const state = (this.state as any);
    const domainName: Detailed_Domain_Name = state.domain_name;
    
    return (
      <SafeArea>
        {state.is_loading? (
          <div>
            <ViewLoader message='Loading Domain Name. Please Wait...' />
          </div>
        ): (
          <div>
          <DomainNameserverEditPanel
            nameservers={state.nameservers}
            isOpen={state.editNameservers}
            onDismiss={(ns_have_updated: boolean) => {
              if(ns_have_updated) {
                this.setState({
                  editNameservers: false,
                  is_loading:      true
                });
                this.load_data();
              }
              else {
                this.setState({editNameservers: false});
              }
              
            }}
            domainName={domainName.domain_name}
          />

          <Breadcrumb
            items={this.breadcrumbItems}
            maxDisplayedItems={5}
            ariaLabel="Breadcrumb with static width"
            overflowAriaLabel="More links"
          />

          <h1>Manage Domain Name ({domainName.domain_name})</h1>

          {/* <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
          >
            This domain name seems to be using external nameservers. This prevents
            you from making changes to your domains DNS records through 121 Digital Console.
          </MessageBar> */}

          <Spacer size="25px" />
        
          <div style={this.styles.panelWrapper}>
            <div style={{width: "50%"}}>
              <Card>
                <h3>Domain Information</h3>
                <Table>
                  <TableRow>
                    <TableCell><b>Order Date</b></TableCell>
                    <TableCell>
                      {domainName.order_date}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Renew Date</b></TableCell>
                    <TableCell>
                      {domainName.renew_date}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Domain Status</b></TableCell>
                    <TableCell>
                      <CheckmarkCircle16Filled color='green' /> Domain Active
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>DNS Security</b></TableCell>
                    <TableCell>
                      <Badge style={{borderRadius: 2, height: 23}}>Not Configured</Badge>
                      <Button size='small' style={{float: 'right'}}>
                        Configure DNSSEC
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Nameservers</b></TableCell>
                    <TableCell>
                      <CheckmarkCircle16Filled color={"green"} /> <img src={live_dns_png} height={16} />
                    </TableCell>
                  </TableRow>
                </Table>
              </Card>
            </div>
            <div style={{width: "50%"}}>
              <Card>
                <DomainNameserversPanel 
                  nameservers={state.nameservers}
                />
                <Spacer size='0px' />

                <div style={{display: 'flex'}}>
                  <Button appearance='primary' onClick={(() => {
                    this.setState({editNameservers: true})
                  })}>Update Nameservers</Button>
                  
                  <Button style={{marginLeft: 5}}>Reset to default</Button>
                </div>

              </Card>
            </div>
          </div>
          
          <Spacer size='25px' />
          <div style={{maxWidth: (1200 + 0), marginLeft: 1}}>
            <Card>
              <h3>DNS Records</h3>
            </Card>
          </div>
        </div>
        )}

      </SafeArea>
    );
  }

}